import { useCallback } from 'react'
import {
  useQueryStates,
  createParser,
  parseAsInteger,
  parseAsString,
  parseAsArrayOf,
  parseAsStringEnum,
} from 'nuqs'
import { HomeRentalTypeEnum, HomeSearchOrderByEnum, HomeSearchOrderEnum } from '@qasa/graphql'
import { parseSearchAreaQuery, stringifySearchArea } from '@qasa/app/src/utils/search'

import type { FindHomeFiltersContextValue, FindHomeFilterValues } from '../context/find-home-filter-context'

const parseAsSearchArea = createParser({
  parse: (value: string) => {
    return parseSearchAreaQuery(value)
  },
  serialize: (value) => {
    return stringifySearchArea(value)
  },
})

const parsers = {
  minMonthlyCost: parseAsInteger,
  maxMonthlyCost: parseAsInteger,
  minSquareMeters: parseAsInteger,
  maxSquareMeters: parseAsInteger,
  minRoomCount: parseAsInteger,
  maxRoomCount: parseAsInteger,
  earliestMoveIn: parseAsString,
  minRentalLength: parseAsInteger,
  homeTypes: parseAsArrayOf(parseAsString).withDefault([]),
  contractTypes: parseAsArrayOf(parseAsString).withDefault([]),
  safeRental: parseAsArrayOf(parseAsString),
  sharedHome: parseAsArrayOf(parseAsString).withDefault([]),
  furnished: parseAsArrayOf(parseAsString).withDefault([]),
  wheelchairAccessible: parseAsArrayOf(parseAsString).withDefault([]),
  householdSize: parseAsInteger,
  searchAreas: parseAsArrayOf(parseAsSearchArea).withDefault([]),
  page: parseAsInteger.withDefault(1),
  checkInDate: parseAsString,
  checkOutDate: parseAsString,
  bedCount: parseAsInteger,
  bedroomCount: parseAsInteger,
  toiletCount: parseAsInteger,
  includedRooms: parseAsArrayOf(parseAsString).withDefault([]),
  minNightlyCost: parseAsInteger,
  maxNightlyCost: parseAsInteger,
  popularTraits: parseAsArrayOf(parseAsString).withDefault([]),
  locationPerks: parseAsArrayOf(parseAsString).withDefault([]),
  equipmentTraits: parseAsArrayOf(parseAsString).withDefault([]),
  rentalType: parseAsStringEnum<HomeRentalTypeEnum>(Object.values(HomeRentalTypeEnum)),
  rules: parseAsArrayOf(parseAsString).withDefault([]),
  order: parseAsStringEnum<HomeSearchOrderEnum>(Object.values(HomeSearchOrderEnum)).withDefault(
    HomeSearchOrderEnum.DESCENDING,
  ),
  orderBy: parseAsStringEnum<HomeSearchOrderByEnum>(Object.values(HomeSearchOrderByEnum)).withDefault(
    HomeSearchOrderByEnum.PUBLISHED_AT,
  ),
  uids: parseAsArrayOf(parseAsString),
}

export function useQueryParamHomeFilters(): Omit<FindHomeFiltersContextValue, 'rentalType'> {
  const [filterValues, setFilterValues] = useQueryStates(parsers, { clearOnDefault: true })

  const handleClearFilterValues = (clearedFilterKeys: (keyof FindHomeFilterValues)[]) => {
    const clearedFilters: Parameters<typeof setFilterValues>[0] = {}
    clearedFilterKeys.forEach((key) => (clearedFilters[key] = null))
    clearedFilters.page = null

    setFilterValues(clearedFilters)
  }

  const handleUpdateFilters = useCallback(
    (newValues: Parameters<typeof setFilterValues>[0], shouldResetPage = true) => {
      setFilterValues(
        {
          ...newValues,
          ...(shouldResetPage && { page: null }),
        },
        { clearOnDefault: true },
      )
    },
    [setFilterValues],
  )

  return {
    filterValues,
    clearFilterValues: handleClearFilterValues,
    updateFilterValues: handleUpdateFilters,
  }
}
